import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_i18n_ftx86CUdf5 from "/app/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.mjs";
import vuetify_icons_RMzWu406ID from "/app/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import strapi_CICqBBANNd from "/app/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import vuetify_no_client_hints_wMKVLl9fyU from "/app/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/app/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  vuetify_i18n_ftx86CUdf5,
  vuetify_icons_RMzWu406ID,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  strapi_CICqBBANNd,
  i18n_yfWm7jX06p,
  vuetify_no_client_hints_wMKVLl9fyU,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]