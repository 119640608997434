import { defineNuxtPlugin } from '#app/nuxt'
import { LazyDialogAddress, LazyDialogAddressItem, LazyDialogDeposit, LazyDialogDeviceInfo, LazyDialogFrame, LazyDialogMuster, LazyDialogOptional, LazyDialogPin, LazyDialogProduct, LazyDialogFeedbackPreorder, LazyDialogOrderInfos, LazyDialogRadioFormType, LazyDialogRequestCompleted, LazyDialogReturnConfirmation, LazyDialogReturnController, LazyLayoutNotification, LazyUtilsValidatedField } from '#components'
const lazyGlobalComponents = [
  ["DialogAddress", LazyDialogAddress],
["DialogAddressItem", LazyDialogAddressItem],
["DialogDeposit", LazyDialogDeposit],
["DialogDeviceInfo", LazyDialogDeviceInfo],
["DialogFrame", LazyDialogFrame],
["DialogMuster", LazyDialogMuster],
["DialogOptional", LazyDialogOptional],
["DialogPin", LazyDialogPin],
["DialogProduct", LazyDialogProduct],
["DialogFeedbackPreorder", LazyDialogFeedbackPreorder],
["DialogOrderInfos", LazyDialogOrderInfos],
["DialogRadioFormType", LazyDialogRadioFormType],
["DialogRequestCompleted", LazyDialogRequestCompleted],
["DialogReturnConfirmation", LazyDialogReturnConfirmation],
["DialogReturnController", LazyDialogReturnController],
["LayoutNotification", LazyLayoutNotification],
["UtilsValidatedField", LazyUtilsValidatedField],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
