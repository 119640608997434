import { default as returnuw4YrIdJ1jMeta } from "/app/pages/form/return.vue?macro=true";
import { default as sp8_45feedbackxqBeCPQ4QmMeta } from "/app/pages/form/sp8-feedback.vue?macro=true";
export default [
  {
    name: "form-return",
    path: "/form/return",
    meta: returnuw4YrIdJ1jMeta || {},
    component: () => import("/app/pages/form/return.vue")
  },
  {
    name: "form-sp8-feedback",
    path: "/form/sp8-feedback",
    meta: sp8_45feedbackxqBeCPQ4QmMeta || {},
    component: () => import("/app/pages/form/sp8-feedback.vue")
  }
]